export const Contactme = () => {
  return (
    <div style={{
        position: "absolute",
        bottom: "3%",

    }}>
      <span>有问题/bug反馈?</span>
      <a href="mailto:ADSR1042@gmail.com" type="email">联系作者</a>
    </div>
  );
};
